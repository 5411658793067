interface SetupObject {
    key: string;
    ref: string;
    chartTimeframe: string;
    maxbars: number;
};

const Utils = {
    randomKey : function (): string {
        let key = '';
        let length = Math.floor(Math.random() * 4) + 5;
        for (let i = 0; i < length; i++) {
            key += String.fromCharCode(Math.floor(Math.random() * 26) + 65);
        }
        return key;
    },

    getNextDay : function (dateString?: string): string {
        let date;
        if (dateString) {
            date = new Date(dateString);
        } else {
            date = new Date();
        }
        date.setDate(date.getDate() + 1);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    },

    getFirstDay : function (d2: string, chartTimeFrame: string, maxbars: number): string {
        let date = new Date(d2);
        switch(chartTimeFrame) {
            case 'Weekly':
                date.setDate(date.getDate() - maxbars * 7);
                break;
            case 'Monthly':
                date.setMonth(date.getMonth() - maxbars);
                break;
            case 'Quarterly':
                date.setMonth(date.getMonth() - maxbars * 3);
                break;
            case 'Annually':
                date.setFullYear(date.getFullYear() - maxbars);
                break;
            default:
                throw new Error(`Invalid chartTimeFrame: ${chartTimeFrame}`);
        }
        date.setDate(date.getDate() - 1);  // Subtract one more day
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    },

    generateChartUrl : function (source: string, setup: SetupObject): string {
        let key = this.randomKey();
        let nextDay = this.getNextDay();

        return `${source}?s=${setup.key}
            &ref=${setup.ref}
            &d1=${this.getFirstDay(nextDay, setup.chartTimeframe, setup.maxbars)}
            &d2=${nextDay}
            &rn=${key}
            &h=300&w=600`;
    }
};

export default Utils;