import React, { useEffect, useRef, useState } from "react";
import {
    Tabs, Tab, Box,
    Grid, Paper, TextField,
    Select, MenuItem
} from "@mui/material";

import Layout from "../components/layout";

import axios from "axios";
import * as echarts from 'echarts';

const BASE_URL_DAY = "https://spdr.resys.workers.dev/view/daily?perpage=";
const BASE_URL_WEEK = "https://spdr.resys.workers.dev/view/weekly?perpage=";


const SPDRPage = (): JSX.Element => {

    const [timePeriod, setTimePeriod] = useState("Day");
    const [perPage, setPerPage] = useState(90);

    const chartRef = useRef(null);
    const chartOfChangeRef = useRef(null);
    const chartOfPriceRef = useRef(null);
    const [data, setData] = useState<{ date: string; price: number; tonnes: number; change: number; }[]>([]);

    useEffect(() => {
        const url = timePeriod === "Day" ? `${BASE_URL_DAY}${perPage}` : `${BASE_URL_WEEK}${perPage}`;

        axios.get( url )
            .then(response => {
                let records = response.data.data;
                records = records.reverse();
                setData(records);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }, [perPage, timePeriod]);

    useEffect(() => {
        if (data.length > 0 && chartRef.current) {

            // chart of  tonnes
            const myChart = echarts.init(chartRef.current);
            const minTonnes = Math.min(...data.map(item => item.tonnes));
            const option = {
                title: {
                    text: 'Khối lượng tích lũy (tấn)',
                    left: 'center',
                    top: 20,
                    textStyle: {
                        color: '#333',
                        fontSize: 24,
                        fontFamily: 'Arial'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: data.map(item => item.date),
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value',
                    min: minTonnes - 50
                },
                series: [
                    {
                        data: data.map(item => item.tonnes),
                        type: 'bar'
                    }
                ]
            };
            myChart.setOption(option);

            // chart of changes
            const myChartOfChange = echarts.init(chartOfChangeRef.current);
            const optionOfChange = {
                title: {
                    text: 'Khối lượng mua bán (tấn)',
                    left: 'center',
                    top: 20,
                    textStyle: {
                        color: '#333',
                        fontSize: 24,
                        fontFamily: 'Arial'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: data.map(item => item.date),
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: data.map(item => item.change),
                        type: 'bar',
                        itemStyle: {
                            color: function (params: { data: number }) {
                                return params.data < 0 ? 'red' : 'orange';
                            }
                        }
                    }
                ]
            };
            myChartOfChange.setOption(optionOfChange);

            // chart of price
            const myChartOfPrice = echarts.init(chartOfPriceRef.current);
            const minPrice = Math.min(...data.map(item => item.price));
            const optionOfPrice = {
                title: {
                    text: 'Vùng giá giao dịch (Dollar / Ounce)',
                    left: 'center',
                    top: 20,
                    textStyle: {
                        color: '#333',
                        fontSize: 24,
                        fontFamily: 'Arial'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: data.map(item => item.date),
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value',
                    min: minPrice - 50
                },
                series: [
                    {
                        data: data.map(item => item.price),
                        type: 'line'
                    }
                ]
            };
            myChartOfPrice.setOption(optionOfPrice);
        }
    }, [data]);

    return (
        <Layout>


            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <h1 style={{ padding: "8px 16px" }}>Tra cứu dữ liệu giao dịch của Quỹ SPDR</h1>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            type="number"
                            value={perPage}
                            onChange={(e) => setPerPage(Number(e.target.value))}
                            label="Số lượng dữ liệu:"
                            variant="outlined"
                        />
                        &nbsp;&nbsp;
                        
                        <Select
                            value={timePeriod}
                            onChange={(e) => setTimePeriod(e.target.value)}
                            label="Time Period"
                            variant="outlined"
                        >
                            <MenuItem value={"Day"}>Ngày</MenuItem>
                            <MenuItem value={"Week"}>Tuần</MenuItem>
                        </Select>
                        
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div ref={chartRef} style={{ width: '100%', height: '250px' }}></div>
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div ref={chartOfChangeRef} style={{ width: '100%', height: '250px' }}></div>
                    </Grid>
                </Grid>
                <br />
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div ref={chartOfPriceRef} style={{ width: '100%', height: '250px' }}></div>
                    </Grid>
                </Grid>

                

            </Box>
        </Layout>
    );
};

export default SPDRPage;