import React, { useState } from "react";
import {
    Tabs, Tab, Box,
    Grid
} from "@mui/material";

import Layout from "../components/layout";
import Utils from "../helpers/utils";
import Setup from "../configs/setup";


// Handle Data
let UnitedStateSetup = Setup.unitedstates;

let GDP = Utils.generateChartUrl(Setup.source, UnitedStateSetup.gdp);
let M2 = Utils.generateChartUrl(Setup.source, UnitedStateSetup.m2);
let InterestRate = Utils.generateChartUrl(Setup.source, UnitedStateSetup.interestRate);

let CPI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.cpi);
let CoreCPI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.corecpi);
let PCE = Utils.generateChartUrl(Setup.source, UnitedStateSetup.pce);
let CorePCE = Utils.generateChartUrl(Setup.source, UnitedStateSetup.corepce);
let PPI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.ppi);

let ManufacturingPMI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.manufacturingPMI);
let NonManufacturingPMI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.nonManufacturingPMI);
let ServicesPMI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.servicesPMI);
let CompositePMI = Utils.generateChartUrl(Setup.source, UnitedStateSetup.compositePMI);

let NonFarmPayrolls = Utils.generateChartUrl(Setup.source, UnitedStateSetup.nonFarmPayrolls);
let UnemploymentRate = Utils.generateChartUrl(Setup.source, UnitedStateSetup.unemploymentRate);

let ConsumerConfidence = Utils.generateChartUrl(Setup.source, UnitedStateSetup.consumerConfidence);
let RetailSales = Utils.generateChartUrl(Setup.source, UnitedStateSetup.retailSales);

let NewHomeSales = Utils.generateChartUrl(Setup.source, UnitedStateSetup.newHomeSales);
let NewHomeSalePrice = Utils.generateChartUrl(Setup.source, UnitedStateSetup.newHomeSalePrice);

let BusinessConfidence = Utils.generateChartUrl(Setup.source, UnitedStateSetup.businessConfidence);
let BusinessBankruptcies = Utils.generateChartUrl(Setup.source, UnitedStateSetup.businessBankruptcies);


const USPage = (): JSX.Element => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Layout>
            <h1 style={{ padding: "8px 16px" }}>United States</h1>
            <br />

            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Tăng trưởng kinh tế" />
                <Tab label="Lạm phát" />
                <Tab label="Hoạt động kinh doanh" />
                <Tab label="Thị trường lao động" />
                <Tab label="Sức mua" />
                <Tab label="Doanh nghiệp" />
            </Tabs>

            {value === 0 && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>GDP</h4>
                            <iframe src={GDP} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>M2</h4>
                            <iframe src={M2} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Lãi suất tái cấp vốn</h4>
                            <iframe src={InterestRate} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {value === 1 && (
                <Box p={2}>
                    <h3>Lạm phát giá:</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>CPI</h4>
                            <iframe src={CPI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Core CPI</h4>
                            <iframe src={CoreCPI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>

                    <br />
                    <h3>Lạm phát chi tiêu cá nhân:</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>PCE</h4>
                            <iframe src={PCE} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Core PCE</h4>
                            <iframe src={CorePCE} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>

                    <br />
                    <h3>Lạm phát chi phí sản xuất:</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4>PPI</h4>
                            <iframe src={PPI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>

                </Box>
            )}

            {value === 2 && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>PMI Sản xuất</h4>
                            <iframe src={ManufacturingPMI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>PMI Phi sản xuất</h4>
                            <iframe src={NonManufacturingPMI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>PMI Dịch vụ</h4>
                            <iframe src={ServicesPMI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>PMI Tổng hợp</h4>
                            <iframe src={CompositePMI} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {value === 3 && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>Số việc làm phi nông nghiệp mới:</h4>
                            <iframe src={NonFarmPayrolls} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Tỉ lệ thất nghiệp:</h4>
                            <iframe src={UnemploymentRate} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {value === 4 && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>Mức độ lạc quan của người tiêu dùng:</h4>
                            <iframe src={ConsumerConfidence} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Doanh số bán lẻ:</h4>
                            <iframe src={RetailSales} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Doanh số bán nhà:</h4>
                            <iframe src={NewHomeSales} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Trung bình giá nhà:</h4>
                            <iframe src={NewHomeSalePrice} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {value === 5 && (
                <Box p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>Mức độ tự tin vào môi trường kinh doanh</h4>
                            <iframe src={BusinessConfidence} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Doanh nghiệp phá sản</h4>
                            <iframe src={BusinessBankruptcies} height="330" width="630" frameBorder="0"></iframe>
                        </Grid>
                    </Grid>
                </Box>
            )}

        </Layout>
    );
};

export default USPage;