import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import UnitedStatesPage from "./pages/unitedstates";
import EuroZonePage from "./pages/eurozone";
import UnitedKingdomPage from "./pages/unitedkingdom";
import JapanPage from "./pages/japan";
import ChinaPage from "./pages/china";
import RussiaPage from "./pages/russia";
import SpdrPage from "./pages/spdr";


const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/UnitedStates" element={<UnitedStatesPage />} />
                <Route path="/EuroZone" element={<EuroZonePage />} />
                <Route path="/UnitedKingdom" element={<UnitedKingdomPage />} />
                <Route path="/Japan" element={<JapanPage />} />
                <Route path="/China" element={<ChinaPage />} />
                <Route path="/Russia" element={<RussiaPage />} />
                <Route path="/SPDR" element={<SpdrPage />} />

                <Route path="*" element={<Navigate to="/UnitedStates" />} />
            </Routes>
        </Router>
    );
};

export default App;
