import React, { useState, useEffect } from "react";
import { IconButton, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Menu as MenuIcon, AccountCircle, ExitToApp, QuestionMark, Topic, Image, People, Settings, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/layout.scss";

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

const Layout = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(localStorage.getItem("sidebarOpen") === "true");

    useEffect(() => {
        localStorage.setItem("sidebarOpen", String(open));
    }, [open]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: open ? drawerWidth : collapsedDrawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: open ? drawerWidth : collapsedDrawerWidth, boxSizing: "border-box" },
                }}
                open={open}
            >
                <Box className="leftPanel" sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", overflowX: "hidden" }}>
                    <Box>
                        <List>
                            {(
                                <div>
                                    <ListItem key="UnitedStates" component={Link} to="/UnitedStates" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/united_states_flag.png" />
                                        {open && <ListItemText primary="Mỹ" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {(
                                <div>
                                    <ListItem key="EuroZone" component={Link} to="/EuroZone" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/euro_flag.png" />
                                        {open && <ListItemText primary="Khu vực châu Âu" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {(
                                <div>
                                    <ListItem key="UnitedKingdom" component={Link} to="/UnitedKingdom" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/united_kingdom_flag.png" />
                                        {open && <ListItemText primary="Anh" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {(
                                <div>
                                    <ListItem key="Japan" component={Link} to="/Japan" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/japan_flag.png" />
                                        {open && <ListItemText primary="Nhật Bản" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {(
                                <div>
                                    <ListItem key="China" component={Link} to="/China" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/china_flag.png" />
                                        {open && <ListItemText primary="Trung Quốc" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {(
                                <div>
                                    <ListItem key="Russia" component={Link} to="/Russia" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/russia_flag.png" />
                                        {open && <ListItemText primary="Nga" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                            {<Divider />}
                            
                            {(
                                <div>
                                    <ListItem key="SPDR" component={Link} to="/SPDR" className="leftPanel-listItem">
                                        <img className="flag_icons" src="/img/russia_flag.png" />
                                        {open && <ListItemText primary="SPDR" className="listText" />}
                                    </ListItem>
                                </div>
                            )}

                        </List>
                    </Box>

                    <div>
                        <IconButton color="inherit" onClick={open ? handleDrawerClose : handleDrawerOpen} sx={{ marginBottom: "2em", marginLeft: "0.5em" }}>
                            {open ? <ChevronLeft className="listIcon" /> : <ChevronRight className="listIcon" />}
                        </IconButton>
                    </div>

                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                {children}
            </Box>
        </Box>
    );
};

export default Layout;