export default {
    "source": "https://tradingeconomics.com/embed/",
    "unitedstates": {
        "gdp": {
            "key": "gdp+cqoq",
            "ref": "/united-states/gdp-growth",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "unitedstamonsupm2",
            "ref": "/united-states/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "fdtr",
            "ref": "/united-states/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "cpi+yoy",
            "ref": "/united-states/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "usacorecpirate",
            "ref": "/united-states/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "pce": {
            "key": "usappiac",
            "ref": "/united-states/pce-price-index-annual-change",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corepce": {
            "key": "usacppiac",
            "ref": "/united-states/core-pce-price-index-annual-change",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "ppi": {
            "key": "usapefeatsy",
            "ref": "/united-states/ppi-ex-food-energy-and-trade-services-yoy",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "unitedstamanpmi",
            "ref": "/united-states/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "nonManufacturingPMI": {
            "key": "unitedstanonmanpmi",
            "ref": "/united-states/non-manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "unitedstaserpmi",
            "ref": "/united-states/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "unitedstacompmi",
            "ref": "/united-states/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "nonFarmPayrolls": {
            "key": "nfp+tch",
            "ref": "/united-states/non-farm-payrolls",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "unemploymentRate": {
            "key": "usurtot",
            "ref": "/united-states/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "usaretailsalesyoy",
            "ref": "/united-states/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "concconf",
            "ref": "/united-states/consumer-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "newHomeSales": {
            "key": "unitedstanewhomsal",
            "ref": "/united-states/new-home-sales",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "newHomeSalePrice": {
            "key": "usaahp",
            "ref": "/united-states/average-house-prices",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "napmpmi",
            "ref": "/united-states/business-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "businessBankruptcies": {
            "key": "unitedstaban",
            "ref": "/united-states/bankruptcies",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },


    },

    "eurozone": {
        "gdp": {
            "key": "eugnemuq",
            "ref": "/euro-area/gdp-growth",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "emuevolvmonsupm2",
            "ref": "/euro-area/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "eurr002w",
            "ref": "/euro-area/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "eccpemuy",
            "ref": "/euro-area/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "euroareacorinfrat",
            "ref": "/euro-area/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "euroareamanpmi",
            "ref": "/euro-area/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "euroareaserpmi",
            "ref": "/euro-area/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "euroareacompmi",
            "ref": "/euro-area/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "employmentChange": {
            "key": "euroareaempcha",
            "ref": "/euro-area/employment-change",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "unemploymentRate": {
            "key": "umrtemu",
            "ref": "/euro-area/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "emuretailsalesyoy",
            "ref": "/euro-area/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "euccemu",
            "ref": "/euro-area/consumer-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "housePriceIndex": {
            "key": "eurhpiy",
            "ref": "/euro-area/house-price-index-yoy",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "euesemu",
            "ref": "/euro-area/business-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "businessBankruptcies": {
            "key": "eurbankruptcies",
            "ref": "/euro-area/bankruptcies",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
    },

    "unitedkingdom": {
        "gdp": {
            "key": "ukgrybzq",
            "ref": "/united-kingdom/gdp-growth",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "unitedkinmonsupm2",
            "ref": "/united-kingdom/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "ukbrbase",
            "ref": "/united-kingdom/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "ukrpcjyr",
            "ref": "/united-kingdom/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "gbrcorecpirate",
            "ref": "/united-kingdom/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "ppi": {
            "key": "gbrpiy",
            "ref": "/united-kingdom/ppi-input-yoy",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "unitedkinmanpmi",
            "ref": "/united-kingdom/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "unitedkinserpmi",
            "ref": "/united-kingdom/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "unitedkincompmi",
            "ref": "/united-kingdom/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "nonFarmPayrolls": {
            "key": "unitedkinnonfarpay",
            "ref": "/united-kingdom/non-farm-payrolls",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "unemploymentRate": {
            "key": "ukueilor",
            "ref": "/united-kingdom/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "gbrretailsalesyoy",
            "ref": "/united-kingdom/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "ukcci",
            "ref": "/united-kingdom/consumer-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "housePriceIndex": {
            "key": "gbrhpiy",
            "ref": "/united-kingdom/house-price-index-yoy",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "eur1uk",
            "ref": "/united-kingdom/business-confidence",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "businessBankruptcies": {
            "key": "unitedkinban",
            "ref": "/united-kingdom/bankruptcies",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },


    },

    "japan": {
        "gdp": {
            "key": "jgdpagdp",
            "ref": "/japan/gdp-growth",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "japanmonsupm2",
            "ref": "/japan/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "bojdtr",
            "ref": "/japan/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "jncpiyoy",
            "ref": "/japan/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "jpncorecpirate",
            "ref": "/japan/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "japanmanpmi",
            "ref": "/japan/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "japanserpmi",
            "ref": "/japan/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "japancompmi",
            "ref": "/japan/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "employmentRate": {
            "key": "japanemprat",
            "ref": "/japan/employment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "unemploymentRate": {
            "key": "jnue",
            "ref": "/japan/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "jpnretailsalesyoy",
            "ref": "/japan/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "jcomacf",
            "ref": "/japan/consumer-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "residentialPropertyPrice": {
            "key": "japanhouind",
            "ref": "/japan/housing-index",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "jnsballi",
            "ref": "/japan/business-confidence",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "businessBankruptcies": {
            "key": "japanban",
            "ref": "/japan/bankruptcies",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },


    },

    "china": {
        "gdp": {
            "key": "cngdpqoq",
            "ref": "/china/gdp-growth",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "chinamonsupm2",
            "ref": "/china/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "chlr12m",
            "ref": "/china/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "cncpiyoy",
            "ref": "/china/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "chinacorinfrat",
            "ref": "/china/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "chinamanpmi",
            "ref": "/china/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "nonManufacturingPMI": {
            "key": "chinanonmanpmi",
            "ref": "/china/non-manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "chinaserpmi",
            "ref": "/china/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "chinacompmi",
            "ref": "/china/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "unemploymentRate": {
            "key": "cnuerate",
            "ref": "/china/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "chnretailsalesyoy",
            "ref": "/china/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "chcsconf",
            "ref": "/china/consumer-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "newHomeSales": {
            "key": "chnnhsy",
            "ref": "/china/new-home-sales-yoy",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "residentialPropertyPrice": {
            "key": "chrpp",
            "ref": "/china/residential-property-prices",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "chbubcin",
            "ref": "/china/business-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },


    },

    "russia": {
        "gdp": {
            "key": "rudpryoy",
            "ref": "/russia/gdp-growth-annual",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "m2": {
            "key": "russiamonsupm2",
            "ref": "/russia/money-supply-m2",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "interestRate": {
            "key": "rrefrate",
            "ref": "/russia/interest-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "cpi": {
            "key": "rucpiyoy",
            "ref": "/russia/inflation-cpi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "corecpi": {
            "key": "russiacorinfrat",
            "ref": "/russia/core-inflation-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "manufacturingPMI": {
            "key": "russiamanpmi",
            "ref": "/russia/manufacturing-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "servicesPMI": {
            "key": "russiaserpmi",
            "ref": "/russia/services-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "compositePMI": {
            "key": "russiacompmi",
            "ref": "/russia/composite-pmi",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "employmentRate": {
            "key": "russiaemprat",
            "ref": "/russia/employment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "unemploymentRate": {
            "key": "ruuer",
            "ref": "/russia/unemployment-rate",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },

        "retailSales": {
            "key": "rusretailsalesyoy",
            "ref": "/russia/retail-sales-annual",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },
        "consumerConfidence": {
            "key": "russiabc",
            "ref": "/russia/consumer-confidence",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "housePriceIndex": {
            "key": "rushpiy",
            "ref": "/russia/house-price-index-yoy",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },
        "residentialPropertyPrice": {
            "key": "rsrpp",
            "ref": "/russia/residential-property-prices",
            "chartTimeframe": "Quarterly",
            "maxbars": 18
        },

        "businessConfidence": {
            "key": "russsiabusdence",
            "ref": "/russia/business-confidence",
            "chartTimeframe": "Monthly",
            "maxbars": 18
        },


    },

};